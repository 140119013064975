export const fields = [
    { key: 'index', label: "No", _style:'min-width:30px'},
    { key: 'select', label: "", _style:'min-width:30px' },
    { key: 'kecamatan', label: "Kecamatan", _style:'min-width:30px' },
    { key: 'nama_poktan', label: "Nama Poktan", _style:'min-width:30px' },
    { key: 'triwulan', label: "Triwulan", _style:'min-width:100px;' },
    { key: 'tahun', label: "Tahun", _style:'min-width:100px;' },
    { key: 'kapasitas_produksi', label: "Kapasitas Produksi", _style:'min-width:30px' },
    { key: 'jenis_bantuan', label: "Jenis Bantuan", _style:'min-width:100px;' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px;' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px;' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
